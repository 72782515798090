<template>
  <el-select
    :value="localValue"
    :size="size"
    :multiple="multiple"
    :disabled="disabled"
    :clearable="clearable"
    :placeholder="placeholder"
    @change="selected"
  >
    <el-option
      v-for="year in years"
      :key="year"
      :label="year"
      :value="year"
    >
    </el-option>
  </el-select>

</template>

<script>

export default {
  name: "cards-year-select",
  components: {},
  props: {
    value: {},
    size: {type: String, default: '' },
    disabled: {type: Boolean, default: false },
    multiple: {type: Boolean, default: true },
    clearable: {type: Boolean, default: true },
    placeholder: {type: String, default: 'Год' },

    yearsDownCount: {type: Number, default: 5},
    // autoSetCurrentYear: {type:Boolean, default: false},
  },

  data() {
    return {
      localValue: this.value,
      years: [],
    }
  },
  beforeMount() {
    const currentYear = new Date().getFullYear();
    this.years = [currentYear+1, currentYear];
    for( let i=1; i<=this.yearsDownCount; i++ ){
      this.years.push(currentYear-i);
    }
  },
  watch: {},
  computed: {},
  methods: {
    selected(value) {
      this.localValue = value;
      this.$emit('input', value);
      this.$emit('change', value);
    }
  },

}
</script>

<style scoped>

</style>